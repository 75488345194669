body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none !important;
}

html {
  background-image: url("../../image/background/ECC55831.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.title{
  background-image: url(../../image/background/title.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 200px;
  margin: auto;
  width: 1000px;
}

.intro{
  padding-top: 48px;
  color: #ffffff;
  font-size: 45px;
  margin-bottom: 5px;
  margin-top: 0;
  font-family: "Fredoka One", cursive;
}

.rcs-inner-container div{
  display: flex;
  flex-wrap: wrap;
}

.form_login{
  background-image: url("../../image/background/form.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 650px;
  margin: auto;
  width: 500px;
  background-position: center;
  display: flex;
}

.input{
  width: 300px;
  font-family: "Fredoka One", cursive;
  font-size: 20px;
}

.MuiInputBase-input{
  font-family: "Fredoka One", cursive !important;
  font-size: 20px !important;
}

.logo{
  float:right;
  margin-right: 90px;
}

.marco{
  background-image: url("../../image/background/marco.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.count{
  background-image: url("../../image/background/button.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: white;
  font-weight: 200;
  display: flex;
}

.avatar{
  position: absolute;
  width: 350px;
}

.avatarDiv{
  width: 350px;
  height: 640px;
  display:flex; 
  margin: auto;
}

.avatar_accesory{
  position: absolute;
  width: 350px;
}

.count_number{
  color: white;
  font-family: "Fredoka One", cursive;
  font-weight: bold;
  font-size: 30px;
  margin: auto 0;
}

.rcs-custom-scroll .rcs-custom-scrollbar{
  width: 50px !important;
  opacity: 1;
}

.rcs-inner-handle{
  background: url("../../image/background/icon_scroll.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.botton_font{
  font-family: "Fredoka One", cursive;
  margin-bottom: 0px;
}

#loginButton{
  background-image: url("../../image/background/button.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 5px 10px 10px 10px;
  color: white;
  font-weight: 200;
  font-size: 25px;
  max-width: 200px;
  height: 66px;
  background-position: center;
}

#avatarButton{
  background-image: url("../../image/background/button.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 5px 10px 10px 10px;
  color: white;
  font-weight: 200;
  font-size: 20px;
  max-width: 250px;
  width: 250px;
  font-family: "Fredoka One", cursive;
  height: 90px;
  background-position: center;
  margin: auto;
  margin-top: 20px;
}

.item{
  background-image: url(https://thumbs.dreamstime.com/b/seamless-pattern-wood-texture-wooden-background-vector-illustration-87903986.jpg);
  padding: 20px;
  background-color: #e8bbbbd9;
  border: solid 4px #756e6f;
  border-radius: 50px;
  box-shadow: 5px 3px 4px 2px rgba(0, 0, 0, 0.2);
}

.item div{
  width: 100%;
}

.item div img{
  margin: auto;
}

.item div div{
  margin: auto;
}

.item_picture{
  width: 200px;
  max-height: 200px;
}

.dressButton{
  background-image: url("../../image/background/button.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 5px 10px 10px 10px;
  color: white;
  font-weight: 200;
  font-size: 25px;
  width: 200px;
  font-family: "Fredoka One", cursive;
  height: 45px;
  background-position: center;
  margin: auto;
  margin-top: 20px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text{
  max-width: 100px;
  margin-right:5px;
}

.buyButton{
  background-image: url("../../image/background/button.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 5px 10px 10px 10px;
  color: white;
  font-weight: 200;
  font-size: 25px;
  width: 200px;
  font-family: "Fredoka One", cursive;
  height: 45px;
  background-position: center;
  margin: auto;
  margin-top: 20px;
}

.buttonDashButton{
  background-image: url("../../image/background/button.png") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  padding: 5px 10px 10px 10px !important;
  color: white !important;
  font-weight: 200 !important;
  font-size: 20px !important;
  max-width: 250px !important;
  width: 250px !important;
  font-family: "Fredoka One", cursive !important;
  height: 90px !important;
  background-position: center !important;
  margin: auto !important;
  margin-top: 20px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cofre{
  width: 300px;
}

.cofre_2{
  width: 300px;
}

@media (max-width:1550px){
  .cofre_2{
    width: 250px;
  }

  .avatar{
    position: absolute;
    width: 350px;
  }
  
  .avatarDiv{
    width: 350px;
    height: 640px;
    display:flex; 
    margin: auto;
  }
  
  .avatar_accesory{
    position: absolute;
    width: 350px;
  }

  .buttonDashButton{
    background-image: url("../../image/background/button.png") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    padding: 5px 10px 10px 10px !important;
    color: white !important;
    font-weight: 200 !important;
    font-size: 20px !important;
    max-width: 250px !important;
    width: 250px !important;
    font-family: "Fredoka One", cursive !important;
    height: 90px !important;
    background-position: center !important;
    margin: auto !important;
    margin-top: 20px !important;
  }

  .buttonDash{
    display: flex;
  }
}

@media (max-width:1300px){
  .cofre_2{
    width: 300px;
  }

  .buttonDash{
    display: inline;
  }

  .avatar{
    position: absolute;
    width: 300px;
  }
  
  .avatarDiv{
    width: 300px;
    height: 640px;
    display:flex; 
    margin: auto;
  }
  
  .avatar_accesory{
    position: absolute;
    width: 300px;
  }

  .buttonDashButton{
    background-image: url("../../image/background/button.png") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    padding: 5px 10px 10px 10px !important;
    color: white !important;
    font-weight: 200 !important;
    font-size: 20px !important;
    max-width: 250px !important;
    width: 250px !important;
    font-family: "Fredoka One", cursive !important;
    height: 90px !important;
    background-position: center !important;
    margin: auto !important;
    margin-top: 20px !important;
  }
}

@media (max-width: 1000px){
  .title{
    background-image: url(../../image/background/title.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 140px;
    margin: auto;
    width: 600px;
  }

  .cofre_2{
    width: 200px;
  }

  .avatarDiv{
    width: 350px;
    height: 580px;
    display:flex; 
    margin: auto;
  }

  .buttonDashButton{
    background-image: url("../../image/background/button.png") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    padding: 5px 10px 10px 10px !important;
    color: white !important;
    font-weight: 200 !important;
    font-size: 20px !important;
    max-width: 200px !important;
    width: 250px !important;
    font-family: "Fredoka One", cursive !important;
    height: 90px !important;
    background-position: center !important;
    margin: auto !important;
    margin-top: 20px !important;
  }

  .intro{
    padding-top: 17px;
    color: #ffffff;
    font-size: 35px;
    margin-bottom: 5px;
    margin-top: 0;
    font-family: "Fredoka One", cursive;
  }

  .cofre{
    width: 200px;
  }

  .rcs-inner-container div{
    display: flex;
    flex-wrap: wrap;
  }

  .form_login{
    background-image: url("../../image/background/form.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 650px;
    margin: auto;
    width: 500px;
    background-position: center;
    display: flex;
  }

  

  .input{
    width: 200px;
    font-family: "Fredoka One", cursive;
    font-size: 20px;
  }

  .MuiInputBase-input{
    font-family: "Fredoka One", cursive !important;
    font-size: 20px !important;
  }

  .logo{
    float:right;
    margin-right: 90px;
    width: 150px;
  }

  .marco{
    background-image: url("../../image/background/marco.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .count{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    color: white;
    font-weight: 200;
    display: flex;
  }

  .avatar{
    position: absolute;
  }

  .avatar_accesory{
    position: absolute;
  }

  .count_number{
    color: white;
    font-family: "Fredoka One", cursive;
    font-weight: bold;
    font-size: 30px;
    margin: auto 0;
  }

  .rcs-custom-scroll .rcs-custom-scrollbar{
    width: 50px !important;
    opacity: 1;
  }

  .rcs-inner-handle{
    background: url("../../image/background/icon_scroll.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .botton_font{
    font-family: "Fredoka One", cursive;
    margin-bottom: 0px;
  }

  #loginButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    max-width: 200px;
    height: 66px;
    background-position: center;
  }

  #avatarButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 20px;
    max-width: 250px;
    width: 250px;
    font-family: "Fredoka One", cursive;
    height: 90px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  .item{
    background-image: url(https://thumbs.dreamstime.com/b/seamless-pattern-wood-texture-wooden-background-vector-illustration-87903986.jpg);
    padding: 20px;
    background-color: #e8bbbbd9;
    border: solid 4px #756e6f;
    border-radius: 50px;
    box-shadow: 5px 3px 4px 2px rgba(0, 0, 0, 0.2);
  }

  .item div{
    width: 100%;
  }

  .item div img{
    margin: auto;
  }

  .item div div{
    margin: auto;
  }

  .item_picture{
    width: 200px;
    max-height: 200px;
  }

  .dressButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    width: 200px;
    font-family: "Fredoka One", cursive;
    height: 45px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text{
    max-width: 100px;
    margin-right:5px;
  }

  .buyButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    width: 200px;
    font-family: "Fredoka One", cursive;
    height: 45px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

@media (max-width: 575px){
  .title{
    background-image: url(../../image/background/title.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100px;
    margin: auto;
    width: 500px;
  }

  .buttonDashButton{
    background-image: url("../../image/background/button.png") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    padding: 5px 10px 10px 10px !important;
    color: white !important;
    font-weight: 200 !important;
    font-size: 20px !important;
    max-width: 250px !important;
    width: 250px !important;
    font-family: "Fredoka One", cursive !important;
    height: 90px !important;
    background-position: center !important;
    margin: auto !important;
    margin-top: 20px !important;
  }

  .cofre{
    width: 200px;
  }

  .intro{
    padding-top: 12px;
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 5px;
    margin-top: 0;
    font-family: "Fredoka One", cursive;
  }

  .rcs-inner-container div{
    display: flex;
    flex-wrap: wrap;
  }

  .form_login{
    background-image: url("../../image/background/form.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
    margin: auto;
    width: 500px;
    background-position: center;
    display: flex;
  }

  .input{
    width: 175px;
    font-family: "Fredoka One", cursive;
    font-size: 20px;
  }

  .MuiInputBase-input{
    font-family: "Fredoka One", cursive !important;
    font-size: 20px !important;
  }

  .logo{
    float:right;
    margin-right: 90px;
    width: 80px;
  }

  .marco{
    background-image: url("../../image/background/marco.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .count{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    color: white;
    font-weight: 200;
    display: flex;
  }

  .avatar{
    position: absolute;
  }

  .avatar_accesory{
    position: absolute;
  }

  .count_number{
    color: white;
    font-family: "Fredoka One", cursive;
    font-weight: bold;
    font-size: 30px;
    margin: auto 0;
  }

  .rcs-custom-scroll .rcs-custom-scrollbar{
    width: 50px !important;
    opacity: 1;
  }

  .rcs-inner-handle{
    background: url("../../image/background/icon_scroll.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .botton_font{
    font-family: "Fredoka One", cursive;
    margin-bottom: 0px;
  }

  #loginButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    max-width: 200px;
    height: 66px;
    background-position: center;
  }

  #avatarButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 20px;
    max-width: 250px;
    width: 250px;
    font-family: "Fredoka One", cursive;
    height: 90px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  .item{
    background-image: url(https://thumbs.dreamstime.com/b/seamless-pattern-wood-texture-wooden-background-vector-illustration-87903986.jpg);
    padding: 20px;
    background-color: #e8bbbbd9;
    border: solid 4px #756e6f;
    border-radius: 50px;
    box-shadow: 5px 3px 4px 2px rgba(0, 0, 0, 0.2);
  }

  .item div{
    width: 100%;
  }

  .item div img{
    margin: auto;
  }

  .item div div{
    margin: auto;
  }

  .item_picture{
    width: 200px;
    max-height: 200px;
  }

  .dressButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    width: 200px;
    font-family: "Fredoka One", cursive;
    height: 45px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text{
    max-width: 100px;
    margin-right:5px;
  }

  .buyButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    width: 200px;
    font-family: "Fredoka One", cursive;
    height: 45px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .avatarDiv{
    height: 400px;
    display: table-cell;
    margin: auto;
  }

  .avatar_accesory{
    height: 400px;
    width: auto;
  }

  .avatar{
    height: 400px;
    width: auto;
  }
}

@media (width: 500px){
  .title{
    background-image: url(../../image/background/title.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100px;
    margin: auto;
    width: 450px;
  }

  .cofre{
    width: 200px;
  }

  .intro{
    padding-top: 12px;
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 5px;
    margin-top: 0;
    font-family: "Fredoka One", cursive;
  }

  .rcs-inner-container div{
    display: flex;
    flex-wrap: wrap;
  }

  .form_login{
    background-image: url("../../image/background/form.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
    margin: auto;
    width: 500px;
    background-position: center;
    display: flex;
  }

  .input{
    width: 175px;
    font-family: "Fredoka One", cursive;
    font-size: 20px;
  }

  .MuiInputBase-input{
    font-family: "Fredoka One", cursive !important;
    font-size: 20px !important;
  }

  .logo{
    float:right;
    margin-right: 90px;
    width: 80px;
  }

  .marco{
    background-image: url("../../image/background/marco.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .count{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    color: white;
    font-weight: 200;
    display: flex;
  }

  .avatar{
    position: absolute;
  }

  .avatar_accesory{
    position: absolute;
  }

  .count_number{
    color: white;
    font-family: "Fredoka One", cursive;
    font-weight: bold;
    font-size: 30px;
    margin: auto 0;
  }

  .rcs-custom-scroll .rcs-custom-scrollbar{
    width: 50px !important;
    opacity: 1;
  }

  .rcs-inner-handle{
    background: url("../../image/background/icon_scroll.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .botton_font{
    font-family: "Fredoka One", cursive;
    margin-bottom: 0px;
  }

  #loginButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    max-width: 200px;
    height: 66px;
    background-position: center;
  }

  #avatarButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 20px;
    max-width: 200px;
    width: 200px;
    font-family: "Fredoka One", cursive;
    height: 90px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  .item{
    background-image: url(https://thumbs.dreamstime.com/b/seamless-pattern-wood-texture-wooden-background-vector-illustration-87903986.jpg);
    padding: 20px;
    background-color: #e8bbbbd9;
    border: solid 4px #756e6f;
    border-radius: 50px;
    box-shadow: 5px 3px 4px 2px rgba(0, 0, 0, 0.2);
  }

  .item div{
    width: 100%;
  }

  .item div img{
    margin: auto;
  }

  .item div div{
    margin: auto;
  }

  .item_picture{
    width: 200px;
    max-height: 200px;
  }

  .dressButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    width: 200px;
    font-family: "Fredoka One", cursive;
    height: 45px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text{
    max-width: 100px;
    margin-right:5px;
  }

  .buyButton{
    background-image: url("../../image/background/button.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5px 10px 10px 10px;
    color: white;
    font-weight: 200;
    font-size: 25px;
    width: 200px;
    font-family: "Fredoka One", cursive;
    height: 45px;
    background-position: center;
    margin: auto;
    margin-top: 20px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .order {
    display: block;
    overflow-x: overlay;
  }

  .order > .row {
    overflow-x: overlay;
    white-space: nowrap;
    display: block;
  }
  .order > .row > .col-8 {
    display: inline-block;
    float: none;
  }
}